import React, { useState, useEffect, useCallback } from 'react';
import dayjs from 'dayjs';
import { Button, Steps, Alert, Spin } from 'antd';
import BusinessProfileForm from './forms/BusinessProfileForm';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import PersonalProfileForm from './forms/PersonProfileForm';
import { motion } from 'framer-motion';
import { tabVariants } from 'animations/variants';
import FinixPaymentMethodForm from '../components/FinixPaymentMethodForm/FinixPaymentMethodForm';
import {
  createMerchantIdentityRequest,
  createMerchantIdentityAssociateRequest,
} from 'services/finix/identityRequest';
import { createFinixMerchantIdentity, createFinixMerchantAssociate } from 'services/api/landlordApi/create';
import { fetchUserIpAddress } from './forms/helpers';
import {
  createMerchantIdentitySchema,
  createAssociatedIdentitySchema,
} from 'services/finix/validators/identitySchema';
import { displayAlert } from 'resources/helpers';
import { updateQuickStartStatusAction } from 'store/actions/landlordActions';
import { DeliveredProcedureOutlined, StepBackwardOutlined, StepForwardOutlined } from '@ant-design/icons';
import CreateMerchantAccount from './CreateMerchantAccount';
import { getFinixMerchantIdentity } from 'services/api/landlordApi/read';
import styled from 'styled-components';
import Text from 'components/Text/Text';

// import fakeMerchIdentity from '../../../resources/fixtures/finix_merchant_identity.json';

const motionProps = {
  initial: 'hide',
  animate: 'show',
  variants: tabVariants,
};

// for testing purposes
const initialFormValuesTest = {
  entity_principal_percentage_ownership: 100,
  entity_business_address_country: 'USA',
  entity_personal_address_country: 'USA',
  entity_business_type: 'INDIVIDUAL_SOLE_PROPRIETORSHIP',
  entity_doing_business_as: 'Other Business',
  entity_business_name: 'Test',
  entity_business_address_line1: 'Test Address',
  entity_business_address_city: 'Test City',
  entity_business_address_region: 'AL',
  entity_business_address_postal_code: '12345',
  entity_business_phone: '111 111 1111',
  entity_incorporation_date: dayjs('12/31/1999'),
  entity_url: 'www.google.com/',
  additional_underwriting_data_business_description: 'This is a test business description',
  additional_underwriting_data_monthly_rental_income: 100000,
  entity_business_tax_id: '987654321',
  entity_ownership_type: 'PRIVATE',
  entity_first_name: 'First',
  entity_last_name: 'Last',
  entity_title: 'Test Title',
  entity_personal_address_line1: '123 Test Street',
  entity_personal_address_city: 'Test City',
  entity_personal_address_region: 'AL',
  entity_personal_address_postal_code: '12345',
  entity_phone: '111 111 1111',
  entity_email: 'test@gmail.com',
  entity_tax_id: '123456789',
  associated_owners: [
    {
      entity_first_name: 'Bron',
      entity_last_name: 'Dron',
      entity_personal_address_line1: '132 South 900 East Street',
      entity_personal_address_city: 'Orem',
      entity_personal_address_postal_code: '84097',
      entity_email: 'chris.dron@revroad.com',
      entity_personal_address_region: 'AL',
      entity_phone: '111 111 1111',
      entity_tax_id: '123456789',
      entity_title: 'test',
      entity_principal_percentage_ownership: '15',
    },
  ],
};

const initialFormValues = {
  entity_principal_percentage_ownership: 100,
  entity_business_address_country: 'USA',
  entity_personal_address_country: 'USA',
  entity_ownership_type: 'PRIVATE',
  additional_underwriting_data_monthly_rental_income: '',
  associated_owners: [],
};

const CreateMerchantStepper = (props) => {
  const {
    propertyGroup,
    setCurrentStep: setCurrentOnboardingStep,
    currentStep: currentOnboardingStep,
    handleResetMigrationOnboarding,
    checkFinixBoardingStatus,
    setShowAcceptPayments,
  } = props;
  // console.log('[CreateMerchantWizard.js]', props);
  const [current, setCurrent] = useState(0);
  const session = useSelector((store) => store.session);
  const quickStart = session.userData.landlordMeta.landlordProfile?.landlordProfile?.quickStart;
  const dispatch = useDispatch();
  const cognitoToken = session.cognitoTokens?.idToken?.jwtToken;
  const [formValues, setFormValues] = useState(initialFormValues);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [stepStatus, setStepStatus] = useState('process');
  const [merchantIdentity, setMerchantIdentity] = useState(null);
  const [merchantAccount, setMerchantAccount] = useState(null);
  const [paymentInstrument, setPaymentInstrument] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [alert, setAlert] = useState({
    isVisible: false,
    message: '',
    type: 'success',
    description: '',
  });
  const [formReady, setFormReady] = useState({
    0: false,
    1: false,
    2: false,
  });

  const handleChangedValues = (changedValues, allValues) => {
    // console.log(
    //   '[CreateMerchantStepper.js] handleChangedValues',
    //   changedValues,
    //   allValues
    // );
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      ...changedValues,
      ...(allValues.associated_owners && {
        associated_owners: [...allValues.associated_owners],
      }),
    }));
  };

  const handleCreateMerchantIdentity = async (values) => {
    setIsLoading(true);
    setStepStatus('wait');

    try {
      const merchantIdentityRequest = createMerchantIdentityRequest({ ...values });
      merchantIdentityRequest.tags = {
        property_group_id: propertyGroup.id || propertyGroup[0].id,
      };

      await createMerchantIdentitySchema.validateAsync(merchantIdentityRequest);
      const res = await createFinixMerchantIdentity(cognitoToken, merchantIdentityRequest);
      setMerchantIdentity(res);
      if (values.entity_principal_percentage_ownership === 25 && values.associated_owners) {
        for (const associate of values.associated_owners) {
          if (!associate.entity_personal_address_country) {
            associate.entity_personal_address_country = 'USA';
          }
          const associateIdentityRequest = createMerchantIdentityAssociateRequest({ ...associate });
          await createAssociatedIdentitySchema.validateAsync(associateIdentityRequest);
          await createFinixMerchantAssociate(cognitoToken, res.merchantIdentityId, associateIdentityRequest);
        }
      }
      setIsLoading(false);
      setStepStatus('process');
      next();
    } catch (err) {
      console.error('ƒ handleCreateMerchantIdentity', err);
      const message =
        err.data?.message?.errors[0]?.message || (typeof err === 'string' && err) || 'Something went wrong';
      setIsLoading(false);
      setStepStatus('error');
      displayAlert(setAlert, message || 'Something went wrong', 'error', 10000);
    }
  };

  const fetchAndSetOnboardingState = useCallback(
    async (propertyGroupId, controller) => {
      setIsFetching(true);
      try {
        const res = await getFinixMerchantIdentity(
          session.cognitoTokens.idToken.jwtToken,
          propertyGroupId,
          controller
        );

        const status = res.onboardingState;

        setMerchantIdentity(res);
        setCurrent(() => {
          switch (status) {
            case 'IDENTITY_CREATED':
              return 2;
            case 'INSTRUMENT_CREATED':
            case 'APPROVED':
            case 'PROVISIONING':
            case 'REJECTED':
              return 3;
            default:
              return 0;
          }
        });
      } catch (err) {
        console.error(err);
      }
      setIsFetching(false);
    },
    [session.cognitoTokens.idToken.jwtToken]
  );

  const reset = () => {
    setCurrent(0);
    setFormValues(initialFormValues);
    setStepStatus('process');
    setMerchantIdentity(null);
    setMerchantAccount(null);
    setPaymentInstrument(null);
    setShowAcceptPayments(false);
    setAlert({
      isVisible: false,
      message: '',
      type: 'success',
      description: '',
    });
  };

  const next = () => {
    setCurrent(current + 1);
  };
  const prev = () => {
    setCurrent(current - 1);
  };

  const steps = [
    {
      title: <StyledTitle>Business Details</StyledTitle>,
      content: (
        <>
          <div>
            <Text size={14} color="black">
              All fields required unles otherwise specified
            </Text>
          </div>
          <motion.div key="business" {...motionProps} data-testid="businessProfileContainerTest">
            <BusinessProfileForm
              handleChangedValues={handleChangedValues}
              formValues={formValues}
              formReady={formReady}
              setFormReady={setFormReady}
              setAlert={setAlert}
            />
          </motion.div>
        </>
      ),
    },
    {
      title: <StyledTitle>Owner Details</StyledTitle>,
      content: (
        <>
          <div>
            <Text size={14} color="black">
              All fields required unles otherwise specified
            </Text>
          </div>
          <motion.div key="personal" {...motionProps} data-testid="personalProfileContainerTest">
            <PersonalProfileForm
              handleChangedValues={handleChangedValues}
              formValues={formValues}
              formReady={formReady}
              setFormReady={setFormReady}
              setAlert={setAlert}
              fileList={fileList}
              setFileList={setFileList}
            />
          </motion.div>
        </>
      ),
    },
    {
      title: <StyledTitle>Settlement Bank</StyledTitle>,
      content: (
        <>
          <div>
            <Text size={14} color="black">
              All fields required unles otherwise specified
            </Text>
          </div>
          <motion.div key="bank" {...motionProps} data-testid="finixPaymentAccountContainerTest">
            <FinixPaymentMethodForm
              merchantIdentity={merchantIdentity}
              paymentInstrument={paymentInstrument}
              setPaymentInstrument={setPaymentInstrument}
              setStepStatus={setStepStatus}
              setMerchantAccount={setMerchantAccount}
              setAlert={setAlert}
              stepStatus={stepStatus}
              setCurrentOnboardingStep={setCurrentOnboardingStep}
              currentOnboardingStep={currentOnboardingStep}
              next={next}
              setDrawerVisible={props.setDrawerVisible}
              setDrawerExpanded={props.setDrawerExpanded}
              type={'bank'}
            />
            <div style={{ margin: '25px' }}>
              <Text size={11} color="grey4">
                By clicking Save, I authorize PayRent Inc. to electronically credit my account and, if necessary,
                electronically debit my account to correct erroneous credits.
              </Text>
            </div>
          </motion.div>
        </>
      ),
    },
    {
      title: <StyledTitle>Create Account</StyledTitle>,
      content: (
        <motion.div key="create-account" {...motionProps} data-testid="createMerchantAccountContainerTest">
          <CreateMerchantAccount
            merchantIdentity={merchantIdentity}
            paymentInstrument={paymentInstrument}
            setPaymentInstrument={setPaymentInstrument}
            setStepStatus={setStepStatus}
            setMerchantAccount={setMerchantAccount}
            setAlert={setAlert}
            stepStatus={stepStatus}
            setCurrentOnboardingStep={setCurrentOnboardingStep}
            currentOnboardingStep={currentOnboardingStep}
            setDrawerVisible={props.setDrawerVisible}
            setDrawerExpanded={props.setDrawerExpanded}
            setShowAcceptPayments={setShowAcceptPayments}
            handleResetMigrationOnboarding={handleResetMigrationOnboarding}
            reset={reset}
            checkFinixBoardingStatus={checkFinixBoardingStatus}
            onboardingFileList={fileList}
          />
        </motion.div>
      ),
    },
  ];

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
    content: item.content,
    description: item.description,
  }));

  const contentStyle = {
    lineHeight: '26px',
    textAlign: 'center',
    marginTop: 18,
    marginBottom: 18,
  };

  useEffect(() => {
    const collectUserDetails = async () => {
      const userIp = await fetchUserIpAddress();
      return {
        additional_underwriting_data_merchant_agreement_ip_address: userIp,
        additional_underwriting_data_merchant_agreement_timestamp: dayjs().toISOString(),
        additional_underwriting_data_merchant_agreement_user_agent: navigator.userAgent,
      };
    };

    collectUserDetails().then((res) => setFormValues((prev) => ({ ...prev, ...res })));
  }, []);

  useEffect(() => {
    // console.log('[CreateMerchantStepper.js] useEffect on merchantAccount', formValues);
    if (currentOnboardingStep && merchantAccount) {
      setTimeout(() => {
        dispatch(
          updateQuickStartStatusAction(
            session.userData.id,
            {
              ...quickStart,
              lastCompletedStep: currentOnboardingStep,
            },
            session.cognitoTokens.idToken.jwtToken
          )
        );
        setCurrentOnboardingStep(currentOnboardingStep + 1);
      }, 5000);
    }
  }, [merchantAccount]);

  useEffect(() => {
    // console.log('[CreateMerchantStepper.js] useEffect on propertyGroup', propertyGroup);
    const controller = new AbortController();

    if (
      (propertyGroup?.length && propertyGroup[0].finixAccount?.onboardingState) ||
      propertyGroup?.finixAccount?.onboardingState
    ) {
      const id = propertyGroup?.id || propertyGroup?.[0].id;
      fetchAndSetOnboardingState(id, controller);
    }

    return () => controller.abort();
  }, [propertyGroup]);

  return (
    <div className="create-merchant-wrapper" style={{ maxWidth: '768px', margin: '0 auto', padding: '18px' }}>
      <Spin spinning={isFetching}>
        <>
          <StyledSteps
            current={current}
            items={items}
            labelPlacement="vertical"
            responsive
            progressDot
            type={isMobile ? 'inline' : 'default'}
            status={stepStatus}
            size="small"
            data-testid="createMerchantStepperTest"
          />
          <div className="alert-container" style={{ marginTop: '18px', height: '60px' }}>
            {alert.isVisible && (
              <Alert
                data-testid="createMerchantErrorTest"
                message={alert.message}
                type={alert.type}
                description={alert.description}
                closable
              />
            )}
          </div>
          <div style={contentStyle}>{steps[current].content}</div>
          <div
            style={{
              textAlign: 'right',
            }}
          >
            {current > 0 && current < 2 && (
              <Button
                size="large"
                style={{
                  margin: '0 8px',
                }}
                onClick={() => prev()}
              >
                <StepBackwardOutlined />
                Previous
              </Button>
            )}
            {current === 0 && (
              <Button
                size="large"
                type="primary"
                onClick={() => next()}
                data-testid="nextButtonTest"
                disabled={!formReady[current]}
              >
                Next
                <StepForwardOutlined />
              </Button>
            )}
            {current === 1 && (
              <Button
                size="large"
                type="primary"
                onClick={() => handleCreateMerchantIdentity(formValues)}
                data-testid="createMerchantIdentityButtonTest"
                disabled={!formReady[current]}
                loading={isLoading}
              >
                Submit
                <DeliveredProcedureOutlined />
              </Button>
            )}
          </div>
        </>
      </Spin>
    </div>
  );
};

const StyledSteps = styled(Steps)`
  max-width: 480px;
  justify-content: center;
  margin: 0 auto;
  .ant-steps-item-title {
    font-size: 15px;
  }
  .ant-steps-item-content {
    width: 120px !important;
  }
`;

const StyledTitle = styled.div`
  width: 70px;
  text-align: center;
`;

export default CreateMerchantStepper;
