import React, { useState, useEffect } from 'react';
import { Space, Result, Button, Descriptions } from 'antd';
import { createFinixMerchant } from 'services/api/landlordApi/create';
import Success from 'components/Success/Success';
import { displayAlert } from 'resources/helpers';
import { useSelector } from 'react-redux';
import Text from 'components/Text/Text';
import Superstar from 'components/Superstar/Superstar';
import { createFileResource } from 'screens/Landing/FinixFileUpload/FinixFileUploadForm';
import { uploadFinixMerchantFile } from 'services/api/landlordApi/update';

export default function CreateMerchantAccount(props) {
  const {
    setMerchantAccount,
    merchantIdentity,
    setStepStatus,
    setAlert,
    setCurrentOnboardingStep,
    handleResetMigrationOnboarding,
    currentOnboardingStep,
    setDrawerVisible,
    setDrawerExpanded,
    reset,
    checkFinixBoardingStatus,
    setShowAcceptPayments,
    onboardingFileList,
  } = props;
  // console.log('[CreateMerchantAccount.js] props', props);
  const [isLoading, setIsLoading] = useState(false);
  const [createMerchantStatus, setCreateMerchantStatus] = useState(null);
  const session = useSelector((store) => store.session);
  const { achTrxFee, debitRateFee, debitTrxFee, creditCardRateFee, creditCardTrxFee, onboardingFee } = useSelector(
    (store) => store.session.userData.servicePlan
  );

  const feeItems = [
    {
      key: '1',
      label: 'ACH Debit Fee',
      children: `$${achTrxFee.toFixed(2)} per transaction`,
    },
    {
      key: '2',
      label: 'Failed ACH Fee',
      children: '$10.00 per transaction',
    },
    {
      key: '3',
      label: 'Credit Card Fee (Paid by Tenant',
      children: `${(creditCardRateFee * 100).toFixed(2)}% + $${creditCardTrxFee.toFixed(2)} per transaction`,
    },
    {
      key: '4',
      label: 'Debit Card Fee (Paid by Tenant)',
      children: `${(debitRateFee * 100).toFixed(2)}% + $${debitTrxFee.toFixed(2)} per transaction`,
    },
  ];

  async function handleCreateMerchantAccount() {
    setIsLoading(true);
    try {
      const data = {
        tags: {
          property_group_id: merchantIdentity.metadata.tags.propertyGroupId,
          landlord_id: session.userData.id,
        },
      };

      const res = await createFinixMerchant(
        session.cognitoTokens.idToken.jwtToken,
        merchantIdentity.merchantIdentityId,
        data
      );
      // console.log('[CreateMerchantAccount.js] ƒ handleCreateMerchantAccount', res);

      if (!!onboardingFileList.length) {
        await Promise.all(
          onboardingFileList.map(async (file) => {
            try {
              const fileResource = await createFileResource(file, res.merchantId);
              const url = `${process.env.REACT_APP_FINIX_API_URL}/files/${fileResource.id}/upload`;

              const formData = new FormData();
              formData.append('file', file);

              return await uploadFinixMerchantFile(url, formData);
            } catch (err) {
              console.error(err);
              return err;
            }
          })
        );
      }

      setStepStatus('finish');
      setCreateMerchantStatus('success');
      setMerchantAccount(res);
      setIsLoading(false);
      checkFinixBoardingStatus && checkFinixBoardingStatus(res);
    } catch (error) {
      console.error('ƒ handleCreateMerchantAccount', error);
      setStepStatus('error');
      setCreateMerchantStatus('fail');
      displayAlert(setAlert, `${error.status}: ${error.statusText}`, 'error');
      setIsLoading(false);
    }
  }

  const handleDone = () => {
    if (setCurrentOnboardingStep) {
      setCurrentOnboardingStep(currentOnboardingStep + 1);
    }

    if (setDrawerVisible) {
      setDrawerVisible(false);
      setDrawerExpanded(false);
      setShowAcceptPayments(false);
    }

    if (handleResetMigrationOnboarding) {
      console.log('reset merchant boarding');
      handleResetMigrationOnboarding();
      reset();
    }
  };

  useEffect(() => {
    if (merchantIdentity) {
      let { onboardingState } = merchantIdentity;
      if (onboardingState === 'APPROVED' || onboardingState === 'PROVISIONING' || onboardingState === 'REJECTED') {
        setCreateMerchantStatus('success');
      }
    }
  }, [merchantIdentity]);

  return (
    <div data-testid="createMerchantAccountTest">
      <Result
        style={{ padding: '0 0 0 0' }}
        status="success"
        icon={
          createMerchantStatus === 'success' ? (
            <Superstar loop={false} style={{ width: 240, margin: '0 auto' }} data-testid="startAnimationTest" />
          ) : (
            <Success loop={false} style={{ width: 240, margin: '0 auto' }} data-testid="successAnimationTest" />
          )
        }
        title={
          createMerchantStatus === 'success' ? (
            <Text size={24} color="black" strong>
              You're a Rockstar!
            </Text>
          ) : (
            <Text size={24} color="black" strong>
              Bank Account Added...
              <br />
              Almost Done!
            </Text>
          )
        }
        subTitle={
          createMerchantStatus === 'success' ? (
            <>
              <Text color="black">
                We'll review your information and email you when you're approved to accept payments. It usually
                takes 1 business day.
              </Text>{' '}
              <Text color="danger" strong>
                You cannot accept payments until your account is approved.
              </Text>
            </>
          ) : (
            <Space direction="vertical">
              <Text color="black">Transaction Fee Summary:</Text>
              <Descriptions
                data-testid="feeItemsTest"
                items={feeItems}
                bordered
                column={2}
                size="small"
                style={{ textAlign: 'left' }}
                layout="vertical"
              />
              <div>
                <Text color="black">
                  By clicking the button below you agree to our{' '}
                  <a href="https://www.payrent.com/terms-conditions-2022/">Terms of Service</a> and the{' '}
                  <a href="https://finix.com/terms-and-policies">Finix Terms of Service</a>.
                  {!!onboardingFee && (
                    <>
                      <br />
                      <span data-testid="setupFeeTextTest">
                        A one-time ${onboardingFee.toFixed(2)} account set-up fee will be charged to the bank
                        account on file.
                      </span>
                    </>
                  )}
                </Text>
              </div>
            </Space>
          )
        }
        extra={
          createMerchantStatus === 'success'
            ? [
                <Button key="submit" type="primary" onClick={handleDone} size="large">
                  Done
                </Button>,
              ]
            : [
                <Button
                  key="submit"
                  type="primary"
                  onClick={handleCreateMerchantAccount}
                  data-testid="createMerchantAccountButtonTest"
                  loading={isLoading}
                  size="large"
                >
                  Start Accepting Payments
                </Button>,
              ]
        }
      />
    </div>
  );
}
