import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import {
  Form,
  Spin,
  Empty,
  Select,
  Typography,
  Input,
  Divider,
  Space as AntSpace,
  AutoComplete as AntAutoComplete,
  ConfigProvider,
  Drawer as AntDrawer,
} from 'antd';
import styled from 'styled-components';
import axios from 'axios';
import Joyride from 'react-joyride';
import { throttle } from 'lodash';

import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import FileType from 'file-type/browser';

import {
  getPropertyGroups,
  getParentProperties,
  getPropertyGroupOnboardingStatus,
  getPropertiesV3,
} from 'services/api/landlordApi/read';
import { updatePaymentGateway } from 'services/api/landlordApi/update';

import { createPropertyGroup, createTransaction } from 'services/api/landlordApi/create';

import { displayAlertAction } from 'store/actions/globalActions';
import { getServicePlanAction, getUserDataV3 } from 'store/actions/sessionActions';
import { setSummaryPaymentType, unsetTransactionRenter } from 'store/actions/landlordActions';
import { documentsPresignedUrl } from 'services/api/common/create';

import { propertiesSteps } from 'resources/landlordTourSteps';
import { scrollTo } from 'resources/helpers';

import Tag from 'components/Tag';

import Text from 'components/Text';
import Button from 'components/Button';
import CardWithTitle from 'components/CardWithTitle';
import AutoComplete from 'components/AutoComplete';
import Space from 'components/Space';
import Onboarding from '../Onboarding';
import RenterDetails from '../components/RenterDetails';
import TransactionPanel from '../components/TransactionPanel';
import PropertyPanel from '../components/PropertyPanel';
import InvitationPanel from '../components/InvitationPanel';
import { ReactComponent as HomeSvg } from 'assets/empty-table-images/Home.svg';
// import PropertiesTable from './components/PropertiesTable';
import GroupTable from './components/GroupTable';
import AccountProfilePanel from '../components/AccountProfilePanel';
import CardButtons from './components/CardButtons';
import PortfolioSettingsPanel from '../components/PortfolioSettingsPanel';
import UnitPanel from '../components/UnitPanel';
import AcceptPayments from '../components/AcceptPayments';
import PaymentDetails from '../components/PaymentDetails';
import OnboardingFullscreen from '../components/OnboardingFullScreen';
import EmptyLarge from 'components/EmptyLarge/EmptyLarge';
import dayjs from 'dayjs';

//import './style.scss';

const { Option } = Select;

const PropertyGroups = (props) => {
  const {
    setShowOnboarding,
    setDrawerVisible,
    showPortfolioSettings,
    setShowPortfolioSettings,
    showUnitPanel,
    setShowUnitPanel,
    setShowOnboardingFullscreen,
    showOnboardingFullscreen,
  } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [form] = Form.useForm();
  const session = useSelector((state) => state.session);
  const global = useSelector((store) => store.global);
  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [editProperty, setEditProperty] = useState(null);
  const [cloneProperty, setCloneProperty] = useState(null);
  const [showTransactionPanel, setShowTransactionPanel] = useState(false);
  const [showPropertyPanel, setShowPropertyPanel] = useState(false);
  const [showInvitationPanel, setShowInvitationPanel] = useState(false);
  const [showEditRenterPanel, setShowEditRenterPanel] = useState(false);
  const [editRenter, setEditRenter] = useState(null);
  const [propertyGroups, setPropertyGroups] = useState({ items: [], totalCount: 0 });
  const [properties, setProperties] = useState({ items: [], totalCount: 0 });
  const [propertyGroup, setPropertyGroup] = useState([]);
  const [selectedParentProperty, setSelectedParentProperty] = useState(null);
  const [drawerExpanded, setDrawerExpanded] = useState(false);
  const [loadingPropertyGroups, setLoadingPropertyGroups] = useState(false);
  const [groupName, setGroupName] = useState('');
  const [creatingGroup, setCreatingGroup] = useState(false);
  const [selectValue, setSelectValue] = useState(false);
  const [searchResult, setSearchResult] = useState([]);

  const cancelToken = useRef(null);
  const [nestedData, setNestedData] = useState({});

  const [showAcceptPayments, setShowAcceptPayments] = useState(false);
  const [showPaymentDetails, setShowPaymentDetails] = useState(false);
  const [onboardingState, setOnboardingState] = useState(null);
  const [wepayStatus, setWepayStatus] = useState(true);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [isDesktop, setDesktop] = useState(window.innerWidth > 576);

  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [isLoading, setIsLoading] = useState({});

  const loadedGroup = useRef(false);

  const cognitoToken = session.cognitoTokens?.idToken?.jwtToken;

  const stripeTimer = useRef(false);

  const updateMedia = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });

  useEffect(() => {
    if (location?.state?.action === 'addProperty' && propertyGroups.items.length === 1) {
      setDrawerVisible(true);
      setShowPropertyPanel(true);
      history.replace({ ...location, state: undefined });
    }
  }, [location, propertyGroups, setDrawerVisible, history]);

  const [pageSize, setPageSize] = useState(10);

  const loadPropertyGroup = async (groupId) => {
    if (propertyGroups.items.length) {
      await handleSelectPropertyGroup(groupId);

      const status = await fetchPropertyGroupStatus([], groupId);

      if (status.ach.state === 'DOCUMENTS_REQUIRED' || status.ach.state === 'REVIEW') {
        setDrawerVisible(true);
        setShowAcceptPayments(true);
      } else {
        setDrawerVisible(true);
        setShowPaymentDetails(true);
      }
    }
  };

  // Invitation panel form

  const [editInvitationProperty, setEditInvitationProperty] = useState(false);

  const propertiesCardRef = useRef(null);

  const [dropdownVisible, setDropdownVisible] = useState({});
  const [unitDropdownVisible, setUnitDropdownVisible] = useState({});
  const [inviteProperty, setInviteProperty] = useState(null);
  const [userId, setUserId] = useState('');

  let timerId = '';

  const onExpand = (expanded, { key }) => {
    const keys = expandedKeys;
    const expandedK = expanded ? keys.concat(key) : keys.filter((k) => k !== key);
    setExpandedKeys(expandedK);
  };

  const resetDropdown = (items) => {
    const object = {};
    for (let i = 0; i < items.length; i++) {
      object[i] = false;
    }
    return object;
  };

  useEffect(() => {
    const controller = new AbortController();
    dispatch(getUserDataV3(session.cognitoTokens.idToken.jwtToken, controller));
    dispatch(getServicePlanAction(session.cognitoTokens.idToken.jwtToken));
    return () => controller.abort();
  }, []);

  useEffect(() => {
    if (!loading && window.innerWidth < 576) {
      scrollTo(propertiesCardRef.current);
    }
  }, [loading]);

  const fetchPropertyGroupStatus = async (pg, id) => {
    try {
      if (propertyGroup.length || pg.length || id) {
        const status = await getPropertyGroupOnboardingStatus(cognitoToken, pg.length ? pg[0].id : id);

        if (session.userData.paymentGateway === 'Wepay') {
          if (status.wepayState === 'active') {
            setWepayStatus(false);
          } else {
            setWepayStatus(true);
          }
        }
        if (session.userData.paymentGateway === 'SilaStripe' || session.userData.paymentGateway === 'Finix') {
          setOnboardingState(status);
          return status;
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchPropertyGroups();
  }, [props.history?.location?.search]);

  useEffect(() => {
    if (userId !== session?.userData?.id && session?.userData?.id !== '') {
      setUserId(session?.userData?.id);
    }
  }, [session]);

  useEffect(() => {
    if (propertyGroup.length) {
      fetchPropertyGroupStatus(propertyGroup);
    }
  }, [propertyGroup]);

  useEffect(() => {
    const params = new URLSearchParams(props.history?.location?.search);
    const groupId = params.get('propertyGroup') || null;
    if (groupId && onboardingState && !loadedGroup.current) {
      loadPropertyGroup(groupId);
      loadedGroup.current = true;
    }
  }, [onboardingState]);

  const fetchProperties = async (groupId, page, loader) => {
    try {
      if (loader) setLoading(true);

      const propResult = await getParentProperties(session.cognitoTokens.idToken.jwtToken, groupId, page, 10);
      setProperties(propResult);
      setDropdownVisible(resetDropdown(propResult.items));
      if (page) setCurrentPage(page);
      if (loader) setLoading(false);
    } catch (error) {
      setLoading(false);
      dispatch(displayAlertAction('', 'warning', error?.data?.errors[0].title || 'Request failed.', true, 5000));
    }
  };

  const fetchPropertyGroups = async (reload, deleteReload) => {
    try {
      if (!reload && !deleteReload) setLoadingPropertyGroups(true);
      const res = await getPropertyGroups(session.cognitoTokens.idToken.jwtToken, 1, 10000);
      let group = [];
      if (!reload || deleteReload) {
        group = res.items.filter((pg) => pg.default);
        setSelectValue(group[0].name);
      }
      if (reload) {
        console.log('Response: ', res);
        console.log('propertyGroup: ', propertyGroup);
        group = res.items.filter((pg) => pg.id === propertyGroup[0].id);
        setSelectValue(group[0].name);
      }
      setPropertyGroup(group);

      setPropertyGroups(res);
      await fetchProperties(group[0].id);
      setLoadingPropertyGroups(false);
    } catch (error) {
      console.log(error);
      setLoadingPropertyGroups(false);
    }
  };

  const handleSelectPropertyGroup = async (v) => {
    const selectedPg = propertyGroups.items.filter((pg) => pg.id === v);
    setPropertyGroup(selectedPg);
    setSelectValue(selectedPg[0].name);
    await fetchProperties(selectedPg[0].id);
  };

  const checkLandlordStatus = (setLoading1, setDisableButton1, setDisableButton2, displayAlert) => {
    try {
      if (timerId !== '') clearInterval(timerId);
      timerId = setInterval(async () => {
        const result = await getPropertyGroupOnboardingStatus(
          session.cognitoTokens.idToken.jwtToken,
          propertyGroup[0].id
        );

        if (result.wepayRegistrationState === 'new2-step2') {
          setLoading1(false);
          setDisableButton1(true);
          setDisableButton2(false);
          clearInterval(timerId);
        }
        if (result.wepayRegistrationState === 'action-required') {
          setLoading1(false);
          setDisableButton1(false);
          setDisableButton2(false);
          clearInterval(timerId);
        }
        if (result.wepayRegistrationState === 'error') {
          setLoading1(false);
          setDisableButton1(false);
          setDisableButton2(false);
          clearInterval(timerId);
          displayAlert('There was an error with your request', 'warning');
        }
      }, 2000);
    } catch (error) {
      if (timerId) clearInterval(timerId);
      setLoading1(false);

      displayAlert('There was an error with your request', 'warning');
    }
  };

  const checkFinixBoardingStatus = () => {
    try {
      if (timerId !== '') clearInterval(timerId);
      timerId = setInterval(async () => {
        const status = await getPropertyGroupOnboardingStatus(
          session.cognitoTokens.idToken.jwtToken,
          propertyGroup[0].id
        );
        setOnboardingState(status);

        const key = status.finixOnboardingState;

        switch (key) {
          case 'APPROVED':
          case 'PROVISIONING':
          case 'REVIEW':
          case 'REJECTED':
            clearInterval(timerId);
            break;
          default:
            break;
        }
      }, 3000);
    } catch (error) {
      if (timerId) clearInterval(timerId);
      console.error('error', error);
    }
  };

  const handleTableChange = async (pagination) => {
    try {
      const page = pagination.current ? pagination.current : 1;
      setLoading(true);
      setCurrentPage(page);

      if (pagination.pageSize) {
        setPageSize(pagination.pageSize);
      }
      await fetchProperties(propertyGroup[0].id, page, false);

      setExpandedKeys([]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      dispatch(displayAlertAction('', 'warning', error?.data?.errors[0].title || 'Request failed.', true, 5000));
    }
  };

  const onboardingStatusParser = (oStatus) => {
    let status = null;
    if (oStatus) {
      if (oStatus.ach.state === 'PASSED' || oStatus.ach.state === 'REGISTERED') {
        if (oStatus.ach.silaSettlement) {
          if (oStatus.ach.silaSettlement.payment_method_state === 'ACTIVE') {
            status = 'ACTIVE';
          }
          if (
            oStatus.ach.silaSettlement.payment_method_state === 'SCHEDULED' &&
            oStatus.ach.silaSettlement.micro_deposits_flow === 1
          ) {
            status = 'ACTION_REQUIRED';
          }
        }
      }
      if (
        oStatus.creditCard.chargesEnabled &&
        oStatus.creditCard.payoutsEnabled &&
        oStatus.ach.state !== 'PASSED'
      ) {
        status = 'ACTION_REQUIRED';
      }
      // if (
      //   !oStatus.creditCard.chargesEnabled &&
      //   !oStatus.creditCard.payoutsEnabled &&
      //   oStatus.creditCard.optIn &&
      //   oStatus.ach.state === 'PASSED'
      // ) {
      //   status = 'ACTION_REQUIRED';
      // }
      if (oStatus.ach.silaSettlement) {
        if (
          (!oStatus.creditCard.chargesEnabled &&
            !oStatus.creditCard.payoutsEnabled &&
            !oStatus.creditCard.optIn &&
            oStatus.ach.silaSettlement.payment_method_state === 'ACTIVE' &&
            oStatus.ach.state === 'PASSED') ||
          (!oStatus.creditCard.chargesEnabled &&
            !oStatus.creditCard.payoutsEnabled &&
            oStatus.creditCard.optIn &&
            oStatus.creditCard.state === 'PENDING' &&
            oStatus.ach.silaSettlement.payment_method_state === 'ACTIVE' &&
            oStatus.ach.state === 'PASSED')
        ) {
          status = 'ACTIVE';
        }
      }
    }

    return status;
  };

  const getPaymentMethodStatus = (onboardingStatus) => {
    // console.log('ƒ getPaymentMethodStatus onboardingStatus', onboardingStatus);
    let achSt = null;
    if (session.userData.paymentGateway === 'Finix') {
      achSt = onboardingStatus?.finixOnboardingState || null;
    }
    if (session.userData.paymentGateway === 'SilaStripe') {
      achSt = onboardingStatus?.ach?.state || null;
      const parsed = onboardingStatusParser(onboardingStatus);
      if (parsed) achSt = parsed;
    }

    switch (achSt) {
      case 'ACTIVE':
        return <Tag color="green">Payments Enabled</Tag>;
      case null:
      case 'NOT_STARTED':
        return <Tag color="default">Payments Not Enabled</Tag>;
      case 'DOCUMENTS_RECEIVED':
      case 'SUCCESS':
        return <Tag color="gold">Payments Pending</Tag>;
      case 'ACTION_REQUIRED':
      case 'DOCUMENTS_REQUIRED':
      case 'REVIEW':
        return (
          <Tag color="yellow" textColor="red">
            Payments Action Required
          </Tag>
        );
      case 'REGISTER_ERROR':
      case 'FAILED':
        return <Tag color="red">Payments Disabled</Tag>;
      case 'PROVISIONING':
        return <Tag color="gold">Payments Pending</Tag>;
      case 'APPROVED':
        return <Tag color="green">Payments Enabled</Tag>;
      case 'REJECTED':
        return <Tag color="red">Payments Disabled</Tag>;
      case 'ACCOUNT_CLOSED':
        return <Tag color="red">Account Closed</Tag>;
      default:
        return <Tag color="default">Payments Not Enabled</Tag>;
    }
  };

  const getTag = (tag) => {
    switch (tag) {
      case 'pastdue':
        return 'Past Due';
      case 'current':
        return 'Paid Up';
      case 'Occupied':
        return 'Occupied';
      case 'Vacant':
        return 'Vacant';
      case 'invitePending':
        return 'Invite Pending';
      default:
        return 'IDK';
    }
  };

  const searchHandler = async (v) => {
    try {
      if (!v) {
        setSearchResult([]);
        handleSelectPropertyGroup(propertyGroup[0].id);
        return;
      }
      const searchvalue = v;
      if (cancelToken.current) {
        // Cancel the previous request before making a new request
        cancelToken.current.cancel();
      }
      cancelToken.current = axios.CancelToken.source();

      const results = await getParentProperties(
        session.cognitoTokens.idToken.jwtToken,
        null,
        null,
        null,
        searchvalue,
        cancelToken.current
      );
      setSearchResult(results.items);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearchSelect = (v, l) => {
    // select the property group
    const group = propertyGroups.items.filter((pg) => +pg.id === +l.property.propertyGroupId);
    setPropertyGroup(group);
    setProperties({ items: [l.property], totalCount: 1 });
    setSelectValue(group[0].name);
    // show the selected property
  };

  const handleSearchProperty = useMemo(() => throttle((p) => searchHandler(p), 1000), [propertyGroup]);

  const handleJoyrideCallback = async (data) => {
    const { status, type, action } = data;

    if (action === 'close' || action === 'skip') {
      dispatch({
        type: 'SET_SHOW_TOUR',
        payload: {
          showMainTour: false,
        },
      });
    }
  };

  const onGroupNameChange = (e) => {
    setGroupName(e?.target?.value || '');
  };

  const handleAddPropertyGroup = async () => {
    if (!groupName) return;
    try {
      const values = {
        name: groupName,
      };
      setCreatingGroup(true);
      const createdGroup = await createPropertyGroup(session.cognitoTokens.idToken.jwtToken, values);
      const res = await getPropertyGroups(session.cognitoTokens.idToken.jwtToken, 1, 10000);
      const group = res.items.filter((pg) => pg.id === createdGroup.id);

      setPropertyGroups(res);
      setProperties({ items: [], totalCount: 0 });
      setPropertyGroup(group);
      setGroupName('');
      setSelectValue(group[0].name);
      setCreatingGroup(false);
      setDrawerVisible(true);
      setShowPortfolioSettings(true);
    } catch (error) {
      console.log(error);
      setCreatingGroup(false);
    }
  };

  const handleRefreshUnits = async (parentPropertyid) => {
    const units = await getPropertiesV3(cognitoToken, parentPropertyid);

    // for (let i = 0; i < units.length; i++) {
    //   if (units[i].billingAccount) {
    //     const baDetail = await getBillingAccount(
    //       session.cognitoTokens.idToken.jwtToken,
    //       units[i].billingAccount.id
    //     );
    //     units[i].baDetail = baDetail;
    //   }
    // }

    setNestedData((state) => ({
      ...state,
      [parentPropertyid]: units.map((u) => ({ ...u, resetLoading: false })),
    }));
  };

  const numberTOrdinalSuffix = (num) => {
    const suffix = ['st', 'nd', 'rd'][((((num + 90) % 100) - 10) % 10) - 1] || 'th';
    return `${num}${suffix}`;
  };

  const handleResetBalance = async (item) => {
    try {
      if (!Object.keys(item.billingAccount).length || item.billingAccount.balance == 0) {
        setNestedData((prevData) => {
          const newData = { ...prevData };

          const nestedDataKey = item.parentPropertyId;
          const units = newData[nestedDataKey];
          const newUnits = units.map((u) => (u.id === item.id ? { ...u, resetLoading: false } : u));
          return { [nestedDataKey]: newUnits };
        });
        return;
      }

      setNestedData((prevData) => {
        const newData = { ...prevData };

        const nestedDataKey = item.parentPropertyId;
        const units = newData[nestedDataKey];
        const newUnits = units.map((u) => (u.id === item.id ? { ...u, resetLoading: true } : u));
        return { [nestedDataKey]: newUnits };
      });

      let chargeType = '';
      const values = {};
      if (item.billingAccount.balance > 0) chargeType = 'Credit';
      if (item.billingAccount.balance < 0) chargeType = 'Fee';

      values.transactionType = chargeType;
      values.memo = 'Reset Account';
      values.transactionDate = dayjs();
      values.transactionAmount = Math.abs(Number(item.billingAccount.balance));
      values.renterId = JSON.stringify({
        id: item.billingAccount.user.userId,
        billingAccount: item.billingAccount.id,
      });

      await createTransaction(values).catch((err) => console.error(err));
      await handleRefreshUnits(item.parentPropertyId);
      setNestedData((prevData) => {
        const newData = { ...prevData };

        const nestedDataKey = item.parentPropertyId;
        const units = newData[nestedDataKey];
        const newUnits = units.map((u) => (u.id === item.id ? { ...u, resetLoading: false } : u));
        return { [nestedDataKey]: newUnits };
      });
    } catch (error) {
      console.log(error);
      dispatch(displayAlertAction('', 'warning', error?.data?.errors[0].title || 'Request failed.', true, 5000));
    }
  };

  const handleUploadFile = async (info, record) => {
    const { file } = info;

    try {
      const type = await FileType.fromBlob(file);

      if (type === undefined) throw Error('Invalid file type');

      if (type?.ext !== 'pdf' && type?.ext !== 'jpg' && type?.ext !== 'jpeg' && type?.ext !== 'png') {
        throw Error('Invalid File Type');
      }
      if (file.size / 1024 / 1020 > 10) {
        throw Error('File Size Exceeds 10 MB');
      }
      const ext = type.mime === 'image/jpeg' ? 'jpeg' : type.ext;
      const res = await documentsPresignedUrl(
        session.cognitoTokens.idToken.jwtToken,
        record.details.billingAccount.id,
        'upload',
        `leasedocument.${ext}`,
        'lease'
      );

      const instance = axios.create({
        headers: { 'Content-Type': file.type },
      });
      delete instance.defaults.headers.common['Authorization'];
      delete instance.defaults.headers.common['Accept'];

      await instance.put(res.presignedUrl, file);
    } catch (err) {
      const { response } = err;
      throw response || err;
    }
  };

  const handleDownloadFile = async (record, filename) => {
    try {
      const res = await documentsPresignedUrl(
        session.cognitoTokens.idToken.jwtToken,
        record.details.billingAccount.id,
        'download',
        filename,
        'lease'
      );
      window.open(res.presignedUrl);
    } catch (error) {
      dispatch(displayAlertAction('', 'warning', error?.data?.errors[0].title || 'Request failed.', true, 5000));
    }
  };

  const handleDeleteFile = async (record, filename) => {
    try {
      await documentsPresignedUrl(
        session.cognitoTokens.idToken.jwtToken,
        record.details.billingAccount.id,
        'delete',
        filename,
        'lease'
      );
    } catch (error) {
      const { response } = error;
      throw response;
    }
  };

  const handleExpand = async (expanded, record) => {
    try {
      if (expandedRowKeys) {
        const added = expandedRowKeys.indexOf(record.id);

        if (added > -1) {
          expandedRowKeys.splice(added, 1);
          setExpandedRowKeys(expandedRowKeys);
          return;
        }
        setExpandedRowKeys([...expandedRowKeys, record.id]);
      } else {
        setExpandedRowKeys([record.id]);
      }

      setIsLoading({
        [record.id]: true,
      });

      const p = properties.items.filter((p) => +p.id === +record.id);

      setSelectedParentProperty(p[0]);
      const unitsV3 = await getPropertiesV3(cognitoToken, record.id);
      // console.log('Unitx v3', unitsV3);
      // const units = await getProperties(false, 1, 10000, false, false, `parent_property_id:${record.id}`);
      // console.log('Units v2', units);
      // for (let i = 0; i < units.length; i++) {
      //   if (units[i].billingAccount) {
      //     const baDetail = await getBillingAccount(
      //       session.cognitoTokens.idToken.jwtToken,
      //       units[i].billingAccount.id
      //     );
      //     units[i].baDetail = baDetail;
      //   }
      // }
      setUnitDropdownVisible({ ...unitDropdownVisible, [record.id]: resetDropdown(unitsV3) });
      setNestedData((state) => ({
        ...state,
        [record.id]: unitsV3.map((u) => ({ ...u, resetLoading: false })),
      }));
      setIsLoading({
        [record.id]: false,
      });
    } catch (error) {
      console.log(error);
      setIsLoading({
        [record.id]: false,
      });
    }
  };
  const handleEditRenter = (item) => {
    const editObject = {
      email: item.billingAccount.user.email,
      id: item.billingAccount.user.userId,
      property: {
        id: item.id,
      },
      phone: item.billingAccount.user.cellPhone,
      billingAccounts: [{ ...item.billingAccount, property: { id: item.id } }],
    };
    setEditRenter(editObject);
    setShowEditRenterPanel(true);
    props.setDrawerVisible(true);
    setDropdownVisible(resetDropdown(properties.items));
  };

  const handleEditProperty = (item) => {
    setEditProperty(item);
    setShowUnitPanel(true);
    props.setDrawerVisible(true);
    setDropdownVisible(resetDropdown(properties.items));
  };

  return (
    <>
      <StyledDrawer
        closable={false}
        placement="right"
        width={drawerExpanded ? '100%' : 414}
        onClose={() => {
          props.setDrawerVisible(false);
          props.setShowOnboarding(false);
          props.setShowAccountProfile(false);
          props.setShowAccountPreferences(false);
          props.setShowPlansBillingPanel(false);
          props.setShowBillingDetailsPanel(false);
          setDrawerExpanded(false);
          setShowTransactionPanel(false);
          setShowPropertyPanel(false);
          setShowInvitationPanel(false);
          setShowEditRenterPanel(false);
          setShowPortfolioSettings(false);
          setShowUnitPanel(false);
          setEditProperty(null);
          setEditInvitationProperty(false);
          setCloneProperty(null);
          setShowAcceptPayments(false);
          setShowPaymentDetails(false);
          dispatch(setSummaryPaymentType(''));
          dispatch(unsetTransactionRenter());
          form.resetFields();
        }}
        open={props.drawerVisible}
        //getContainer={false}
        styles={{
          body: {
            padding: 0,
          },
        }}
      >
        {props.drawerVisible && showEditRenterPanel && (
          <RenterDetails
            editRenter={editRenter}
            setDrawerVisible={props.setDrawerVisible}
            setEditRenter={setEditRenter}
            setShowEditRenterPanel={setShowEditRenterPanel}
            sender="propertyGroups"
            // fetchProperties={fetchPropertiesDry}
          />
        )}
        {props.drawerVisible && props.showOnboarding && (
          <Onboarding
            setDrawerVisible={props.setDrawerVisible}
            setShowOnboarding={props.setShowOnboarding}
            checkLandlordStatus={checkLandlordStatus}
            updatePaymentGateway={updatePaymentGateway}
          />
        )}
        {props.drawerVisible && showTransactionPanel && (
          <TransactionPanel
            setDrawerVisible={props.setDrawerVisible}
            setShowTransactionPanel={setShowTransactionPanel}
            // fetchProperties={fetchPropertiesDry}
          />
        )}
        {props.drawerVisible && showPropertyPanel && (
          <PropertyPanel
            setDrawerVisible={props.setDrawerVisible}
            setShowPropertyPanel={setShowPropertyPanel}
            setSelectedParentProperty={setSelectedParentProperty}
            setShowUnitPanel={setShowUnitPanel}
            editProperty={editProperty}
            setEditProperty={setEditProperty}
            setCloneProperty={setCloneProperty}
            cloneProperty={cloneProperty}
            fetchProperties={fetchProperties}
            setCurrentPage={setCurrentPage}
            editInvitationProperty={editInvitationProperty}
            setShowInvitationPanel={setShowInvitationPanel}
            propertyGroup={propertyGroup}
            fetchPropertyGroups={fetchPropertyGroups}
            currentPage={currentPage}
            properties={properties}
            setExpandedRowKeys={setExpandedRowKeys}
            expandedRowKeys={expandedRowKeys}
            handleExpand={handleExpand}
          />
        )}
        {props.drawerVisible && showInvitationPanel && (
          <InvitationPanel
            setDrawerVisible={props.setDrawerVisible}
            setShowInvitationPanel={setShowInvitationPanel}
            inviteProperty={inviteProperty}
            // fetchProperties={fetchPropertiesDry}
            setEditProperty={setEditProperty}
            setShowUnitPanel={setShowUnitPanel}
            editInvitationProperty={editInvitationProperty}
            setEditInvitationProperty={setEditInvitationProperty}
            setShowTransactionPanel={setShowTransactionPanel}
            form={form}
          />
        )}
        {props.drawerVisible && props.showAccountProfile && (
          <AccountProfilePanel
            setShowAccountProfile={props.setShowAccountProfile}
            setDrawerVisible={props.setDrawerVisible}
          />
        )}
        {/* Moved to LandlordMenu.js */}
        {/* {props.drawerVisible && props.showAccountPreferences && (
          <AccountPreferencesPanel
            setDrawerVisible={props.setDrawerVisible}
            setShowAccountPreferences={props.setShowAccountPreferences}
            propertyGroup={propertyGroup}
            page={currentPage}
            fetchProperties={fetchProperties}
          />
        )}
        {props.drawerVisible && props.showPlansBillingPanel && (
          <PlansBillingsPanel
            setDrawerVisible={props.setDrawerVisible}
            setShowPlansBillingPanel={props.setShowPlansBillingPanel}
            setShowBillingDetailsPanel={props.setShowBillingDetailsPanel}
            showBillingDetailsPanel={props.showBillingDetailsPanel}
          />
        )}
        {props.drawerVisible && props.showBillingDetailsPanel && (
          <BillingDetailsPanel
            setDrawerVisible={props.setDrawerVisible}
            setShowBillingDetailsPanel={props.setShowBillingDetailsPanel}
            setShowPlansBillingPanel={props.setShowPlansBillingPanel}
            showBillingDetailsPanel={props.showBillingDetailsPanel}
          />
        )} */}
        {props.drawerVisible && showPortfolioSettings && (
          <PortfolioSettingsPanel
            setShowPortfolioSettings={setShowPortfolioSettings}
            setDrawerVisible={setDrawerVisible}
            setDrawerExpanded={setDrawerExpanded}
            propertyGroup={propertyGroup}
            fetchPropertyGroups={fetchPropertyGroups}
            setShowAcceptPayments={setShowAcceptPayments}
            setShowOnboarding={props.setShowOnboarding}
            setShowPaymentDetails={setShowPaymentDetails}
            setOnboardingState={setOnboardingState}
            onboardingState={onboardingState}
            onboardingStatusParser={onboardingStatusParser}
          />
        )}
        {props.drawerVisible && showUnitPanel && (
          <UnitPanel
            selectedParentProperty={selectedParentProperty}
            setShowUnitPanel={setShowUnitPanel}
            setDrawerVisible={setDrawerVisible}
            handleRefreshUnits={handleRefreshUnits}
            editProperty={editProperty}
            setEditProperty={setEditProperty}
            editInvitationProperty={editInvitationProperty}
            setShowInvitationPanel={setShowInvitationPanel}
            cloneProperty={cloneProperty}
            propertyGroup={propertyGroup[0]}
            fetchProperties={fetchProperties}
            page={currentPage}
          />
        )}
        {props.drawerVisible && showAcceptPayments && (
          <AcceptPayments
            propertyGroup={propertyGroup}
            fetchPropertyGroups={fetchPropertyGroups}
            setShowOnboarding={setShowOnboarding}
            setShowAcceptPayments={setShowAcceptPayments}
            setDrawerVisible={setDrawerVisible}
            setDrawerExpanded={setDrawerExpanded}
            checkLandlordStatus={checkLandlordStatus}
            setOnboardingState={setOnboardingState}
            onboardingState={onboardingState}
            stripeTimer={stripeTimer}
            fetchPropertyGroupStatus={fetchPropertyGroupStatus}
            checkFinixBoardingStatus={checkFinixBoardingStatus}
          />
        )}
        {props.drawerVisible && showPaymentDetails && (
          <PaymentDetails
            setShowPaymentDetails={setShowPaymentDetails}
            setDrawerVisible={setDrawerVisible}
            onboardingState={onboardingState}
            propertyGroup={propertyGroup}
            fetchPropertyGroups={fetchPropertyGroups}
            fetchPropertyGroupStatus={fetchPropertyGroupStatus}
            setOnboardingState={setOnboardingState}
          />
        )}
      </StyledDrawer>
      {/*  Removed from UI 4.0.0 */}
      {/* <NewsPanel
        title="What's New @PayRent"
        placement="right"
        closable={true}
        onClose={() => {
          props.setNewsDrawerVisible(false);
        }}
        open={props.newsDrawerVisible}
        posts={false}
        isOpen={props.newsDrawerVisible}
      /> */}
      {showOnboardingFullscreen && (
        <OnboardingFullscreen
          showOnboardingFullscreen={showOnboardingFullscreen}
          setShowOnboardingFullscreen={setShowOnboardingFullscreen}
          setShowOnboarding={setShowOnboarding}
          setDrawerVisible={setDrawerVisible}
          isDesktop={isDesktop}
          checkLandlordStatus={checkLandlordStatus}
          stripeTimer={stripeTimer}
        />
      )}
      <section className="screen screen--property-groups" data-testid="propertiesScreenTest">
        <Joyride
          callback={handleJoyrideCallback}
          continuous
          // getHelpers={getHelpers}
          run={global.tour.showMainTour && !props.drawerVisible && !props.showOnboarding}
          scrollToFirstStep
          showProgress
          showSkipButton
          steps={propertiesSteps}
          styles={{
            options: {
              zIndex: 19,
            },
          }}
        />
        {/* <Cards
          setDrawerVisible={props.setDrawerVisible}
          setShowTransactionPanel={setShowTransactionPanel}
          setShowPropertyPanel={setShowPropertyPanel}
          setShowInvitationPanel={setShowInvitationPanel}
          setEditProperty={setEditProperty}
        /> */}
        <Space vertical={20} />
        <StyledDiv>
          <StyledSelect
            showSearch
            optionFilterProp="children"
            size="large"
            placeholder="Select Portfolio"
            onChange={handleSelectPropertyGroup}
            value={selectValue}
            dropdownRender={(menu) => (
              <>
                {!session.userData.servicePlan.metaFeatures.mULTIPLEPROPERTYGroup ? (
                  <>
                    <Space vertical={20} />
                    <Text size={16} color="black" centered>
                      Do you manage multiple properties with separate tax IDs and bank accounts?
                    </Text>
                    <Text size={16} color="black" centered>
                      Manage them from a single login!
                    </Text>
                    <Space vertical={25} />
                    <Button
                      color="yellow"
                      alignment="center"
                      onClick={() => history.push('/landlord/service-plans')}
                    >
                      UPGRADE
                    </Button>
                    <Space vertical={5} />
                    <Text
                      color="violet"
                      size={14}
                      underline
                      centered
                      onClick={() => history.push('/landlord/service-plans')}
                      pointer
                    >
                      Learn more
                    </Text>
                  </>
                ) : (
                  <>
                    {menu}
                    <Divider style={{ margin: '8px 0' }} />
                    <AntSpace align="center" style={{ padding: '0 8px 4px' }}>
                      <Input placeholder="Enter Name" value={groupName} onChange={onGroupNameChange} />
                      <Spin spinning={creatingGroup}>
                        <Typography.Link style={{ whiteSpace: 'nowrap' }} onClick={handleAddPropertyGroup}>
                          <PlusOutlined /> Add Group
                        </Typography.Link>
                      </Spin>
                    </AntSpace>
                  </>
                )}
              </>
            )}
            filterOption={(input, option) =>
              option.children.toLocaleLowerCase().includes(input.toLocaleLowerCase())
            }
            filterSort={(optionA, optionB) =>
              optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
            }
          >
            {propertyGroups.items
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((pg) => (
                <Option value={pg.id} key={pg.id}>
                  {pg.name}
                </Option>
              ))}
          </StyledSelect>
          <Space vertical={10} />

          <AutoComplete
            allowClear
            value={value}
            onSelect={handleSearchSelect}
            onSearch={handleSearchProperty}
            onChange={(data) => setValue(data)}
            placeholder={
              <>
                <SearchOutlined style={{ fontSize: '14px', marginRight: '4px' }} />
                <span>Search</span>
              </>
            }
            notFoundContent="Not found"
          >
            {searchResult.map((r) => (
              <AntAutoComplete.Option key={r.id} property={r} value={r.address}>
                {r.address}
              </AntAutoComplete.Option>
            ))}
          </AutoComplete>
        </StyledDiv>
        <Space vertical={25} />
        <div ref={propertiesCardRef} id="anchor">
          {loadingPropertyGroups ? (
            <Spin>
              <CardWithTitle
                title=""
                bodyPadding="0px 0px 15px 0px"
                data-testid="propertiesCardTest"
                className="propertiesTour"
                // key="loading"
                extra={
                  <CardButtons
                    setShowPropertyPanel={setShowPropertyPanel}
                    setDrawerVisible={setDrawerVisible}
                    setShowPortfolioSettings={setShowPortfolioSettings}
                    screenWidth={screenWidth}
                    getPaymentMethodStatus={getPaymentMethodStatus}
                  />
                }
              >
                <StyledEmpty image={Empty.PRESENTED_IMAGE_SIMPLE} className="custom-empty" />
              </CardWithTitle>
            </Spin>
          ) : (
            !!propertyGroup.length && (
              <CardWithTitle
                title={propertyGroup[0].name}
                bodyPadding="0px 0px 15px 0px"
                data-testid="propertiesCardTest"
                className="propertiesTour"
                extra={
                  <CardButtons
                    setShowPropertyPanel={setShowPropertyPanel}
                    setDrawerVisible={setDrawerVisible}
                    setShowPortfolioSettings={setShowPortfolioSettings}
                    screenWidth={screenWidth}
                    propertyGroup={propertyGroup[0]}
                    fetchPropertyGroups={fetchPropertyGroups}
                    setLoadingPropertyGroups={setLoadingPropertyGroups}
                    setSelectValue={setSelectValue}
                    loading={loading}
                    loadingPropertyGroups={loadingPropertyGroups}
                    getPaymentMethodStatus={getPaymentMethodStatus}
                    wepayStatus={wepayStatus}
                    onboardingState={onboardingState}
                    fetchPropertyGroupStatus={fetchPropertyGroupStatus}
                  />
                }
              >
                <ConfigProvider
                  renderEmpty={() => (
                    <EmptyLarge
                      description="No properties yet but properties for this portfolio will be listed here."
                      image={<HomeSvg />}
                    />
                  )}
                >
                  <GroupTable
                    getTag={getTag}
                    handleResetBalance={handleResetBalance}
                    setShowUnitPanel={setShowUnitPanel}
                    setDrawerVisible={setDrawerVisible}
                    screenWidth={screenWidth}
                    properties={properties}
                    handleTableChange={handleTableChange}
                    setShowPropertyPanel={setShowPropertyPanel}
                    loading={loading}
                    setEditProperty={setEditProperty}
                    dropdownVisible={dropdownVisible}
                    setDropdownVisible={setDropdownVisible}
                    resetDropdown={resetDropdown}
                    fetchProperties={fetchProperties}
                    page={currentPage}
                    propertyGroup={propertyGroup}
                    setSelectedParentProperty={setSelectedParentProperty}
                    setUnitDropdownVisible={setUnitDropdownVisible}
                    unitDropdownVisible={unitDropdownVisible}
                    nestedData={nestedData}
                    setNestedData={setNestedData}
                    setShowInvitationPanel={setShowInvitationPanel}
                    setInviteProperty={setInviteProperty}
                    setCloneProperty={setCloneProperty}
                    numberTOrdinalSuffix={numberTOrdinalSuffix}
                    handleUploadFile={handleUploadFile}
                    handleDeleteFile={handleDeleteFile}
                    handleDownloadFile={handleDownloadFile}
                    expandedRowKeys={expandedRowKeys}
                    setExpandedRowKeys={setExpandedRowKeys}
                    isLoading={isLoading}
                    handleExpand={handleExpand}
                    propertyGroupId={propertyGroup[0].id}
                    handleEditRenter={handleEditRenter}
                    handleEditProperty={handleEditProperty}
                  />
                </ConfigProvider>
              </CardWithTitle>
            )
          )}
        </div>
      </section>
    </>
  );
};

const StyledDrawer = styled(AntDrawer)``;

const StyledDiv = styled.div`
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 575px) {
    flex-direction: column;
  }
`;

const StyledSelect = styled(Select)`
  .ant-select-selector {
    height: 36px !important;
  }
  width: 24.4%;
  .ant-select-selection-placeholder {
    color: #bfbfbf !important;
    font-size: 14px;
  }
  @media screen and (max-width: 1680px) {
    width: 24.5%;
  }
  @media screen and (max-width: 1199px) {
    width: 49.2%;
  }
  @media screen and (max-width: 991px) {
    width: 49.5%;
  }
  @media screen and (max-width: 575px) {
    width: 100%;
    margin-bottom: 20px;
  }
`;

const StyledEmpty = styled(Empty)`
  .ant-table-tbody > tr > td {
    border-bottom: 0px !important;
  }
`;

export default PropertyGroups;
