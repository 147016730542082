import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Drawer, Layout } from 'antd';
import styled, { css } from 'styled-components';
import { fetchSystemMessages } from 'services/api/common/read';
import { v4 as uuidv4 } from 'uuid';

// Icons
import { MenuFoldOutlined } from '@ant-design/icons';

import { getServicePlanAction } from 'store/actions/sessionActions';

// Components
import AccountStatus from 'components/AccountStatus';
import AlertFade from 'components/AlertFade';
import TenantMobileMenu from './components/TenantMobileMenu';
import LandlordMobileMenu from './components/LandlordMobileMenu';
import DrawerLogo from './components/DrawerLogo';
import DrawerMenu from './components/DrawerMenu';
import SystemMessage from 'components/SystemMessage/SystemMessage';
import dayjs from 'dayjs';
import { fetchUserIpAddress, getGeolocation } from 'screens/Landlord/FinixOnboarding/forms/helpers';
import { createSessionLog } from 'services/api/common/create';

//import './style.scss';
/*
   sample message saved to db.system_messages
 const fakeSystemMessageProps = JSON.stringify([
          {
            message: '',
            description:
              'PayRent is transitioning to a new payment processor. Please update your payment information by June 1st, 2024 to avoid service disruption.',
            type: 'warning',
            displayAlert: true,
            displayConditions: [
              { path: 'userData.paymentGateway', value: 'Wepay' },
              { path: 'userRole', value: 'landlord' },
            ],
            action: {
              showAction: true,
              label: 'Update',
              fn: 'return history.push("/landlord/wepay-migration");',
            },
          },
          {
            message: '',
            description: `You're the best tenant in the world. Keep up the good work!`,
            type: 'success',
            displayAlert: true,
            displayConditions: [{ path: 'userRole', value: 'tenant' }],
            action: {
              showAction: false,
            },
          },
        ]);
 */

const { Header, Content, Sider } = Layout;

const AppLayout = (props) => {
  const dispatch = useDispatch();
  const userRole = useSelector((state) => state.session.userRole);
  const session = useSelector((state) => state.session);
  const token = session.cognitoTokens.idToken.jwtToken;
  const global = useSelector((state) => state.global);
  const [isVisible, setIsVisible] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const systemMessagesRef = React.useRef([]);
  const fetchedMessagesRef = React.useRef(false);

  const fetchAndSetSession = React.useCallback(
    async (controller) => {
      try {
        if (fetchedMessagesRef.current) return;
        fetchedMessagesRef.current = true;
        const response = await fetchSystemMessages(token, controller);
        // console.log('response', response);
        systemMessagesRef.current = response.map((sysMsg) => sysMsg.message);
        const userAgentData = navigator.userAgentData?.toJSON() || {};
        let userGeolocation = {};

        if ('geolocation' in navigator) {
          const position = await getGeolocation();
          userGeolocation = {
            accuracy: position.coords.accuracy,
            altitude: position.coords.altitude,
            altitudeAccuracy: position.coords.altitudeAccuracy,
            heading: position.coords.heading,
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            speed: position.coords.speed,
          };
        }

        const userIp = await fetchUserIpAddress();

        const sessionData = {
          id: uuidv4(),
          userId: session.userData.cognito['custom:customerId'],
          ip: userIp,
          userAgent: navigator.userAgent,
          metadata: { ...userAgentData, geolocation: userGeolocation },
          createdAt: dayjs().toISOString(),
        };
        // console.log(sessionData);
        await createSessionLog(token, sessionData);

        // check for onboarding
      } catch (err) {
        console.error(err);
      }
    },
    [session.userData.cognito, token]
  );

  useEffect(() => {
    dispatch(getServicePlanAction(session.cognitoTokens.idToken.jwtToken));
  }, []);

  const showDrawer = (e) => {
    if (e) e.preventDefault();
    setIsVisible(true);
  };

  const onClose = () => {
    setIsVisible(false);
  };

  const toggleSider = (e) => {
    if (e) e.preventDefault();
    setIsCollapsed(!isCollapsed);
  };

  useEffect(() => {
    const controller = new AbortController();
    fetchAndSetSession(controller);
    // return () => controller.abort();
  }, [session.cognitoTokens.idToken.jwtToken]);

  return (
    <Layout className="payrent-app app-layout">
      <Header
        className={userRole === 'tenant' ? 'app-layout__header tenant-mobile-header' : 'app-layout__header'}
        style={{
          position: 'fixed',
          zIndex: 21,
          width: '100%',
          backgroundColor: userRole === 'tenant' ? '#fff' : '#541388',
          bottom: userRole === 'tenant' ? '0' : 'unset',
          padding: userRole === 'landlord' && '0px 20px',
        }}
      >
        {userRole === 'landlord' && <LandlordMobileMenu showDrawer={showDrawer} />}
        {userRole === 'tenant' && <TenantMobileMenu />}
      </Header>

      <Sider
        width={240}
        collapsed={isCollapsed}
        breakpoint="lg"
        onBreakpoint={(broken) => {
          if (broken) {
            setIsCollapsed(true);
          } else {
            setIsCollapsed(false);
          }
        }}
        className="app-layout__sider"
        data-testid="siderTest"
      >
        <div className="app-layout__sider-child">
          <div className="app-layout__sider-header">
            <DrawerLogo compact={isCollapsed} userRole={userRole} />
            <MenuFoldOutlined
              className="app-layout__sider-menu-hold"
              onClick={toggleSider}
              data-testid="toggleTest"
            />
          </div>
          <DrawerMenu
            closeDrawer={setIsVisible}
            setDrawerVisible={props.setDrawerVisible}
            setShowOnboarding={props.setShowOnboarding}
            setShowAccountProfile={props.setShowAccountProfile}
            setShowAccountPreferences={props.setShowAccountPreferences}
            setShowPlansBillingPanel={props.setShowPlansBillingPanel}
            setShowOnboardingOverlay={props.setShowOnboardingOverlay}
            setShowOnboardingFullscreen={props.setShowOnboardingFullscreen}
            isCollapsed={isCollapsed}
          />
        </div>
      </Sider>
      <Content
        style={{ position: userRole === 'landlord' && 'relative' }}
        data-testid="mainComponent"
        customertype={userRole}
      >
        {userRole === 'landlord' && (
          <>
            <AccountStatus
              user={session.userData}
              setDrawerVisible={props.setDrawerVisible}
              showOnboarding={props.showOnboarding}
              setShowOnboarding={props.setShowOnboarding}
              setShowPlansBillingPanel={props.setShowPlansBillingPanel}
              setNewsDrawerVisible={props.setNewsDrawerVisible}
              setShowOnboardingOverlay={props.setShowOnboardingOverlay}
            />
          </>
        )}
        {userRole === 'landlord' && (
          <AlertFade
            isVisible={global.alert.isVisible}
            type={global.alert.type}
            message={global.alert.message}
            description={global.alert.description}
            closable={global.alert.closable}
            isCollapsed={isCollapsed}
            section="mainAlert"
            width="100"
            addClass="main-alert"
            position="fixed"
          />
        )}
        <Drawer
          title={<DrawerLogo />}
          placement="left"
          closable={false}
          onClose={onClose}
          open={isVisible}
          styles={{ body: bodyStyle, header: headerStyle, content: drawerStyle }}
          width={220}
          data-testid="drawerTest"
        >
          <DrawerMenu
            closeDrawer={setIsVisible}
            setDrawerVisible={props.setDrawerVisible}
            setShowOnboarding={props.setShowOnboarding}
            setShowAccountProfile={props.setShowAccountProfile}
            setShowAccountPreferences={props.setShowAccountPreferences}
            setShowPlansBillingPanel={props.setShowPlansBillingPanel}
            setShowOnboardingOverlay={props.setShowOnboardingOverlay}
            setShowOnboardingFullscreen={props.setShowOnboardingFullscreen}
          />
        </Drawer>
        {!!systemMessagesRef.current?.length &&
          systemMessagesRef.current.map((sysMsg, index) => (
            <SystemMessage key={index} {...sysMsg} data-testid="systemMessageTest" />
          ))}
        <ScreenContainer userRole={userRole}>{props.children}</ScreenContainer>
        {/* <HelpFloatButton /> */}
      </Content>
    </Layout>
  );
};

const ScreenContainer = styled.div`
  padding: 10px;
  height: auto;
  min-height: calc(100vh - 180px);
  max-width: 1440px;
  margin: auto;
  ${(props) => {
    return css`
      @media screen and (max-width: 1680px) {
        max-width: ${props.userRole === 'landlord' ? '1280' : '920'}px !important;
      }
    `;
  }};
`;

// Drawer
const drawerStyle = {
  backgroundColor: '#122C34',
};

const headerStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '96px',
  backgroundColor: '#122C34',
  background: '#122C34',
  borderBottom: 0,
};

const bodyStyle = {
  padding: '0 0 31px 0',
};

export default AppLayout;
